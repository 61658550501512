export default function parseError(err) {
  if (err.response && err.response.status === 422) {
    let errors = err.response.data.errors;
    if (Array.isArray(errors[Object.keys(errors)[0]])) {
      errors = errors[Object.keys(errors)[0]];
      return errors[Object.keys(errors)[0]];
    } else {
      return errors[Object.keys(errors)[0]];
    }
  } else if (
    ((err.response && err.response.status === 404) ||
      (err.response && err.response.status === 403))
  ) {
    return err.response?.data?.message || "Not allowed";
  } else if (err.response && err.response.status === 500) {
    return "Server Error. Try Later.";
  } else {
    return err;
  }
}
